import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useSelector } from 'react-redux';

import { Badge } from '../common/Badge/Badge';
import { Button } from '../common/Button/Button';
import { Text } from '../Text/Text';
import WishlistIcon from '../../assets/icons/wishlist-icon.svg';
import WishlistIconFilled from '../../assets/icons/wishlist-icon-filled.svg';
import BeanIcon from '../../assets/icons/beans-icon.svg';
import { capitalizeFirstLetter } from '../../helpers/textHelpers';
import {
  useAddProductToWishlistMutation,
  useGetWishlistProductsQuery,
  useRemoveProductFromWishlistMutation,
} from '../../services/user';
import { useAuth } from '../../hooks/useAuth';
import { checkIfOutOfStock } from '../../helpers/productHelpers';
import { AddToCartButton } from '../common/Button/AddToCartButton';
import { Tooltip } from '../Tooltip/Tooltip';
import { ProductImage } from '../ProductImage/ProductImage';

import './productItem.scss';

export const ProductItem = ({
  product: {
    id, name, tags, list_price, vat_price, addons, image_url, grouping, is_purchasable,
  },
}) => {
  const { t } = useTranslation('application');
  const currency = useSelector((state) => state.region.currency);
  const [addToWishlist, { isSuccess: isAddSuccessful }] = useAddProductToWishlistMutation();
  const [removeFromWishlist, { isSuccess: isRemoveSuccessful }] = useRemoveProductFromWishlistMutation();
  const user = useAuth();
  const { data: wishlistProducts = [] } = useGetWishlistProductsQuery(!user && skipToken);
  const [onWishlist, setOnWishlist] = useState(false);

  const notify = (message, type) => {
    toast(<Text type="subtitle2">{message}</Text>, {
      type,
    });
  };

  // eslint-disable-next-line consistent-return
  const handleAddToWishlist = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!user) {
      return false;
    }
    try {
      await addToWishlist(id);
    } catch (e) {
      console.error(e);
      notify(t('notifications.error'), 'error');
    }
  };

  // eslint-disable-next-line consistent-return
  const handleRemoveFromWishlist = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!user) {
      return false;
    }
    try {
      await removeFromWishlist(id);
    } catch (e) {
      console.error(e);
      notify(t('notifications.error'), 'error');
    }
  };

  useEffect(() => {
    if (wishlistProducts.length === 0 || !user) setOnWishlist(false);
    setOnWishlist(wishlistProducts.findIndex((el) => el.id === +id) >= 0);
  }, [wishlistProducts]);

  useEffect(() => {
    if (isAddSuccessful) {
      notify(t('notifications.wishlist.add'), 'success');
    }
  }, [isAddSuccessful]);

  useEffect(() => {
    if (isRemoveSuccessful) {
      notify(t('notifications.wishlist.remove'), 'info');
    }
  }, [isRemoveSuccessful]);

  return (
    <motion.div
      key={id}
      animate={{ opacity: 1, y: 0 }}
      initial={{ opacity: 0, y: -20 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.5 }}
      className="product"
    >
      <div className="product-inner">
        <Link to={`/products/${id}`} className="product__board">
          <div className="product__image-container">
            <ProductImage source={image_url} className="product__image" alt={name} />
          </div>
          <div className="product__badges">
            {tags?.map((tag) => (
              <Badge key={tag.key} type={tag.key} />
            ))}
          </div>
          <div className="product__details">
            <div className="product__socials">
              <Tooltip text={user ? '' : t('products.product.disabledWishlist')}>
                <Button
                  icon={onWishlist ? <WishlistIconFilled /> : <WishlistIcon />}
                  disabled={!user}
                  onClick={(event) => (
                    onWishlist ? handleRemoveFromWishlist(event) : handleAddToWishlist(event)
                  )}
                />
              </Tooltip>
            </div>
            <div className="product__info">
              <h1 className="product__name">{name}</h1>
              <div className="product__price">
                {(parseFloat(list_price) + parseFloat(vat_price)).toFixed(2)}
                {' '}
                {currency}
                <div className="product__vat-included">
                  {t('products.product.priceInclVat')}
                </div>
              </div>
            </div>
            {grouping === 'coffee' && (
              <p className="product__description">
                <span>
                  <BeanIcon />
                </span>
                {capitalizeFirstLetter(t('products.product.beansInfo'))}
              </p>
            )}
            <AddToCartButton
              disabled={checkIfOutOfStock(tags) || !is_purchasable}
              buttonType="standard"
              productId={id}
              hasAddons={addons}
            />
          </div>
        </Link>
      </div>
    </motion.div>
  );
};
