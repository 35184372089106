import { api } from './api';

export const regionInformationApi = api.injectEndpoints({
  reducerPath: 'regionInformationApi',
  endpoints: (builder) => ({
    getRegionInformation: builder.query({
      query: () => ({
        url: '/current_region',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetRegionInformationQuery,
} = regionInformationApi;
