import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '../../hooks/useCurrentScreenWidth';
import { ProductBadges } from '../Product/ProductBadges';
import { useProductImage } from '../ProductImage/useProductImage';
import { ProductPreview } from '../ProductPreview/ProductPreview';
import { Text } from '../Text/Text';

import './summaryItem.scss';

const formatVariants = (productVariants) => productVariants.map((variant) => `${variant.name}: ${variant.value}`).join(', ');

const formatModificators = (productmodificators) => productmodificators.map((modificator) => `${modificator.name}`).join(', ');

export const SummaryItem = ({
  id, name, tags, image_url, quantity, totalQuantityPrice, vatPrice, variants, modificators,
}) => {
  const { t } = useTranslation('application');
  const currency = useSelector((state) => state.region.currency);
  const max575 = useMediaQuery('(max-width: 575px)');

  const { productImage } = useProductImage(id, variants);

  return (
    <div className="summary-item" id="item">
      <div className="summary-item__container">
        <div className="summary-item__product-view">
          {!max575 && <ProductBadges badges={tags} />}
          <ProductPreview productImage={productImage || image_url} productCount={parseInt(quantity, 10)} />
        </div>
        <div>
          <div className="summary-item__product-info">
            <Text className="summary-item__name" type="headline2">
              {name}
            </Text>
            {variants.length > 0 && <Text type="subtitle2">{formatVariants(variants)}</Text>}
            {modificators.length > 0 && <Text type="subtitle2">{formatModificators(modificators)}</Text>}
            <Text className="summary-item__price" type="body2">
              {(parseFloat(totalQuantityPrice) + (vatPrice ? (parseFloat(vatPrice) * parseFloat(quantity)) : 0)).toFixed(2)}
              {' '}
              {currency}
            </Text>
            {vatPrice && parseFloat(vatPrice) !== 0 && (
            <Text className="summary-item" type="body3">
              {t('products.product.vatIncluded')}
            </Text>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
