import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-grid-system';
/* eslint-disable import/no-unresolved */
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Text } from '../../components/Text/Text';
import { useMediaQuery } from '../../hooks/useCurrentScreenWidth';
import { Badge } from '../../components/common/Badge/Badge';
import { ProductImage } from '../../components/ProductImage/ProductImage';

export const NeededProductsSection = ({ neededProducts }) => {
  const { t } = useTranslation('application', { keyPrefix: 'products.product' });
  const currency = useSelector((state) => state.region.currency);
  const moreThan1024 = useMediaQuery('(min-width: 1024px)');
  const moreThan576 = useMediaQuery('(min-width: 576px)');

  const calculateSlidesPerView = () => {
    if (moreThan1024) {
      return 6;
    }
    if (moreThan576) {
      return 3;
    }
    return 2;
  };

  if (neededProducts.length > 0) {
    return (
      <Row style={{ paddingBottom: '10vmin' }}>
        <Col sm={12}>
          <Text type="headline2">{t('mightNeed')}</Text>
          <Swiper slidesPerView={calculateSlidesPerView()} loop={!(neededProducts.length <= calculateSlidesPerView())}>
            {neededProducts.map((product, index) => (
              <SwiperSlide key={index}>
                <Link to={`/products/${product.id}`} className="other-stuff-item">
                  <div className="other-stuff-item__badge-container">
                    {product.tags.length > 0 && product.tags.map((tag, i) => <Badge key={i} type={tag.key} />)}
                  </div>
                  <ProductImage source={product.image_url} alt="product you might need" className="other-stuff-item__image" />
                  <div className="other-stuff-item__content">
                    <Text type="body1" className="other-stuff-item__name">
                      {product.name}
                    </Text>
                    <Text type="body1" className="other-stuff-item__price">
                      {product.list_price}
                      {' '}
                      {currency}
                    </Text>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </Col>
      </Row>
    );
  }
  return null;
};
