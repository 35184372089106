import { createSlice } from '@reduxjs/toolkit';
import { regionInformationApi } from '../../services/region';

const initialState = {
  locale: '',
  currency: '',
};

const regionSlice = createSlice({
  name: 'region',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(regionInformationApi.endpoints.getRegionInformation.matchFulfilled, (state, action) => {
      state.locale = action.payload.region;
      state.currency = action.payload.currency;
    });
  },
});

export const regionReducer = regionSlice.reducer;
