import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Text } from '../../components/Text/Text';

const formattedPrice = (price) => parseFloat(price).toFixed(2);

export const ProductPrice = ({ totalPrice, quantity, vatPrice }) => {
  const { t } = useTranslation('application');
  const currency = useSelector((state) => state.region.currency);
  const priceExcludingVAT = formattedPrice(parseFloat(totalPrice) - parseFloat(vatPrice) * parseFloat(quantity));

  return (
    <>
      <Text type="headline2" className="product-box__price">
        {formattedPrice(totalPrice)}
        {' '}
        {currency}
      </Text>
      <Text type="subtitle2" className="product__vat-info">
        {t('products.product.priceInclVat')}
        {' '}
        (
        {t('products.product.priceExecVatSar', { price: `${priceExcludingVAT}` })}
        )
      </Text>
    </>
  );
};
